<template>
    <Button label="Exportar" class="p-button-secondary p-mr-2" icon="pi pi-file-excel" @click="exportar()" v-if="mostrarBtnExportar" :disabled="desabilitarBtn" />
</template>

<script>
import CredoresServices from './services';

export default {
    props: {
        credores: {
            type: Array,
            default: () => [],
        },

        projeto: {
            type: Object,
            default: () => {},
        },
    },

    methods: {
        exportar() {
            this.$store.dispatch('addRequest');
            let credoresDto = {
                credoresId: this.credores.map((c) => c.credorId),
            };
            CredoresServices.exportarCredores(credoresDto).then((response) => {
                if (response && response.success) {
                    let nomeArquivo = `Listagem_Credores_${this.projeto.nome.split(' ')[0]}_${this.$moment(new Date()).format('DD-MM-YYYY_HH-mm')}.xlsx`;
                    this.$download(response.data, nomeArquivo);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Credores',
                        detail: 'Falha ao exportar listagem do credores',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    computed: {
        mostrarBtnExportar() {
            return this.$temAcessoView('CREDORES-04');
        },

        desabilitarBtn() {
            return !this.credores || this.credores.length === 0;
        },
    },
};
</script>
