export const estados = [
  {value: 'AC', text: 'Acre'},
  {value: 'AL', text: 'Alagoas'},
  {value: 'AP', text: 'Amapá'},
  {value: 'AM', text: 'Amazonas'},
  {value: 'BA', text: 'Bahia'},
  {value: 'CE', text: 'Ceará'},
  {value: 'DF', text: 'Distrito Federal'},
  {value: 'ES', text: 'Espírito Santo'},
  {value: 'GO', text: 'Goiás'},
  {value: 'MA', text: 'Maranhão'},
  {value: 'MT', text: 'Mato Grosso'},
  {value: 'MS', text: 'Mato Grosso do Sul'},
  {value: 'MG', text: 'Minas Gerais'},
  {value: 'PA', text: 'Pará'},
  {value: 'PB', text: 'Paraíba'},
  {value: 'PR', text: 'Paraná'},
  {value: 'PE', text: 'Pernambuco'},
  {value: 'PI', text: 'Piauí'},
  {value: 'RJ', text: 'Rio de Janeiro'},
  {value: 'RN', text: 'Rio Grande do Norte'},
  {value: 'RS', text: 'Rio Grande do Sul'},
  {value: 'RO', text: 'Rondônia'},
  {value: 'RR', text: 'Roraima'},
  {value: 'SC', text: 'Santa Catarina'},
  {value: 'SP', text: 'São Paulo'},
  {value: 'SE', text: 'Sergipe'},
  {value: 'TO', text: 'Tocantins'}
]

export const estados2 = [
  {value: 'AC', text: 'AC'},
  {value: 'AL', text: 'AL'},
  {value: 'AP', text: 'AP'},
  {value: 'AM', text: 'AM'},
  {value: 'BA', text: 'BA'},
  {value: 'CE', text: 'CE'},
  {value: 'DF', text: 'DF'},
  {value: 'ES', text: 'ES'},
  {value: 'GO', text: 'GO'},
  {value: 'MA', text: 'MA'},
  {value: 'MT', text: 'MT'},
  {value: 'MS', text: 'MS'},
  {value: 'MG', text: 'MG'},
  {value: 'PA', text: 'PA'},
  {value: 'PB', text: 'PB'},
  {value: 'PR', text: 'PR'},
  {value: 'PE', text: 'PE'},
  {value: 'PI', text: 'PI'},
  {value: 'RJ', text: 'RJ'},
  {value: 'RN', text: 'RN'},
  {value: 'RS', text: 'RS'},
  {value: 'RO', text: 'RO'},
  {value: 'RR', text: 'RR'},
  {value: 'SC', text: 'SC'},
  {value: 'SP', text: 'SP'},
  {value: 'SE', text: 'SE'},
  {value: 'TO', text: 'TO'}
]
